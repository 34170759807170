.App-header {
  background-color: var(--overlay-color); 
  color: white;
  padding: 50px 20px 20px 20px;
}

.App-footer {
  background-color: var(--overlay-color); 
  color: white;
  padding: 20px 20px 50px 20px;
  bottom: 0;
}

body {
  background-color: #66b6fb;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

.content,
.content.container,
.content.mt-4 {
  flex: 1;
  height: 100%;
  padding: 25px;
}

.content.row.div {
  vertical-align: center;
}

.profile-image {
  background-image: url('../resources/eu\ e\ fuji.jpg');
  background-size: contain; /* Cover the entire component area */
  background-position: center;
  background-repeat: no-repeat; /* Prevent the image from repeating */
  padding-top: 80%; /* Aspect ratio of 16:9 */
  position: relative; /* Necessary for absolutely positioned content */
}