:root {
  --overlay-color: rgba(0, 55, 55, 0.5); /* Semi-transparent layer */
}

.App {
  text-align: center;
  height: 100vh; /* Full height of the viewport */
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Spread header and footer */
}

body {
  background-color: #66b6fb;
}
